import { Column } from "components/Table/Simple";
import { formatDateTime } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "id",
        label: "Shop ID",
        visible: true,
    },
    {
        field: "name",
        label: "Name",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "slug",
        label: "Slug",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "createdAt",
        label: "Created At",
        visible: true,
        render: formatDateTime
    },
    {
        field: "updatedAt",
        label: "Updated At",
        visible: true,
        render: formatDateTime
    }
]