import React, { useEffect, useState } from "react";
import SimpleTable, { SimpleTableProps } from "../Simple";
import CRUDTable, { CRUDTableProps } from "../CRUD";
import Pagination from "components/Pagination";
import { useInputDialog } from "components/Dialog/Input";
import _ from "lodash";
import { InputFormat } from "components/Form/Input";
import { toast } from "react-toastify";

export interface MultiSelectFilter {
    field: string;
    label: string;
    options: {
        name: string;
        value: any;
    }[]
}

export interface PagingTableData {
    data: any[];
    page: number;
    limit: number;
}

export interface PagingTableProps extends CRUDTableProps {
    fetchData: (page: number, limit: number, additionalParams?: any) => Promise<PagingTableData>;
    windowSize?: number;
    additionalParams?: AdditionalParams;
    isCrudable?: boolean;
    disableNav?: boolean;
    refreshKey?: string;
}

interface AdditionalParams {
    [key: string]: string | string[];
}

const PagingTable = ({
    fetchData,
    windowSize = 10,
    isCrudable = false,
    refreshKey = "",
    disableNav = false,
    ...rest
}: PagingTableProps) => {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [nPage, setNPage] = useState(1);
    const { DialogProvider, openDialog } = useInputDialog({ format: InputFormat.NUMBER });

    useEffect(() => {
        console.log("[PagingTable] useEffect [refreshKey]", refreshKey);
        fetchTableData();
    }, [refreshKey]);

    const fetchTableData = async () => {
        try {
            const ret = await fetchData(1, windowSize);
            const { data: tempTableData, page: tempCurrentPage, limit: nData } = ret;
            setNPage(Math.ceil(nData / windowSize));
            setCurrentPage(tempCurrentPage);
            setTableData(tempTableData);
        } catch (e) {

        }
    }

    const onFirstButtonClicked = async () => {
        const ret = await fetchData(1, windowSize);
        const { data: tempTableData, page: tempCurrentPage } = ret;
        setCurrentPage(tempCurrentPage);
        setTableData(tempTableData);
    }

    const onPrevButtonClicked = async () => {
        const ret = await fetchData(currentPage - 1, windowSize);
        if (ret.page < 1) return;
        const { data: tempTableData, page: tempCurrentPage } = ret;
        setCurrentPage(tempCurrentPage);
        setTableData(tempTableData);
    }

    const onNextButtonClicked = async () => {
        const ret = await fetchData(currentPage + 1, windowSize);
        if (ret.page > nPage) return;
        const { data: tempTableData, page: tempCurrentPage } = ret;
        setCurrentPage(tempCurrentPage);
        setTableData(tempTableData);
    }

    const onLastButtonClicked = async () => {
        const ret = await fetchData(nPage, windowSize);
        const { data: tempTableData, page: tempCurrentPage } = ret;
        setCurrentPage(tempCurrentPage);
        setTableData(tempTableData);
    }

    const onPageNavClicked = async () => {
        if(disableNav){
            return;
        }
        const result = await openDialog(`페이지 이동(1 - ${nPage})`, currentPage);
        if (_.isNil(result)) {
            return;
        }
        const page = Number(result);
        if (page < 1 || page > nPage) {
            toast.warn(`페이지는 1부터 ${nPage}까지 입력 가능합니다`);
            return;
        }
        const ret = await fetchData(page, windowSize);
        const { data: tempTableData, page: tempCurrentPage } = ret;
        setCurrentPage(tempCurrentPage);
        setTableData(tempTableData);
    }

    return (
        <div>
            {isCrudable ?
                <CRUDTable data={tableData} {...rest} />
                : <SimpleTable data={tableData} {...rest} />
            }
            <Pagination
                onFirstButtonClick={onFirstButtonClicked}
                onPrevButtonClick={onPrevButtonClicked}
                onNextButtonClick={onNextButtonClicked}
                onLastButtonClick={onLastButtonClicked}
                onPageNavClick={onPageNavClicked}
                currentPage={currentPage}
                nPage={nPage}
            />
            <DialogProvider />
        </div>
    )
}

export default PagingTable;