import Card from 'components/Card';
import React, { useEffect, useRef, useState } from 'react';
import PageTitle from 'components/Title/Page';
import QuillEditorWithImageUploader from 'components/Form/Editor/Quill/WithImageUploader';
import Input, { InputFormat } from 'components/Form/Input';
import InputUpperLabel from 'components/Form/Input/Label/InputUpper';
import FileInput from 'components/Form/Input/File';
import axios from 'axios';
import _ from 'lodash';
import Button from 'components/Form/Button';
import { uploadCdnImg } from 'utils/file';
import { MinusCircleIcon, PhotoIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import HorizontalDragList from 'components/Drag/HorizontalList';
import ImgDropper from 'components/Form/Input/ImgDropper';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const ProductEditor = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const [imgUrls, setImgUrls] = useState([]);
    const quillRef = useRef(null);
    const [formData, setFormData] = useState({
        shopId: "",
        categoryId: "",
        productName: "",
        productDescription: "",
        price: "",
    });

    useEffect(() => {
        console.log("[useEffect] product_id", productId);
        if (_.isNil(productId)) {
            return;
        }
        fetchData();
    }, [productId]);

    const fetchData = async () => {
        try {
            const ret = await axios.get(`https://rest.39f.jp/products/${productId}`);
            console.log("[fetchData] ret", ret);
            const newFormData = {
                shopId: ret.data.shopId,
                categoryId: ret.data.categoryId,
                productName: ret.data.name,
                productDescription: ret.data.description,
                price: ret.data.price,
            }
            setFormData(newFormData);
            const newImgUrls = [];
            if (!_.isNil(ret.data.mainImageUrl)) {
                newImgUrls.push(ret.data.mainImageUrl);
            }
            if (!_.isNil(ret.data.subImageUrls)) {
                newImgUrls.push(...ret.data.subImageUrls);
            }
            console.log("[fetchData] newImgUrls", newImgUrls);
            setImgUrls(newImgUrls);
            const detailContents = ret.data.detail;
            if (_.isNil(quillRef.current)) {
                alert('Quill is not ready');
                return;
            }
            quillRef.current.setContents(detailContents);
        } catch (e) {

        }
    }

    const onFilesSelected = async (files) => {
        console.log("[onFilesSelected] files", files);
        let urls = [];
        if (files.length > 0) {
            for await (const file of files) {
                const url = await uploadCdnImg(file);
                urls.push(url);
            }
        }
        console.log("!!!!! URLS", urls)
        setImgUrls([...imgUrls, ...urls]);
    }

    const onDelete = async () => {
        const ok = window.confirm('Are you sure to delete this product?');
        if (!ok) {
            return;
        }
        try {
            const ret = await axios.delete(`https://rest.39f.jp/products/${productId}`);
            console.log("[onDelete] ret", ret);
            toast.success('Product deleted successfully');
            navigate(-1);
        } catch (e) {
            console.log("[onDelete] error", e);
            toast.error('Failed to delete product');
        }
    }

    const onCreate = async () => {
        console.log("[onCreate] formData", formData, quillRef.current.getContents());
        const detailContents = quillRef.current.getContents();
        const mainImgUrl = imgUrls[0];
        const subImgUrls = imgUrls.slice(1);
        const body = {
            shopId: Number(formData.shopId),
            categoryId: Number(formData.categoryId),
            name: formData.productName,
            description: formData.productDescription,
            detail: detailContents.ops,
            price: Number(formData.price),
            mainImageUrl: mainImgUrl,
            subImageUrls: subImgUrls,
        }
        console.log("[onCreate] body", body);
        try {
            const ret = await axios.post('https://rest.39f.jp/products', body);
            console.log("[onCreate] ret", ret);
            toast.success('Product saved successfully');
        } catch (e) {
            console.log("[onCreate] error", e);
            toast.error('Failed to save product');
        }
    }

    const onSave = async () => {
        console.log("[onSave] formData", formData, quillRef.current.getContents());
        const detailContents = quillRef.current.getContents();
        const mainImgUrl = imgUrls[0];
        const subImgUrls = imgUrls.slice(1);
        const body = {
            shopId: Number(formData.shopId),
            categoryId: Number(formData.categoryId),
            name: formData.productName,
            description: formData.productDescription,
            detail: detailContents.ops,
            price: Number(formData.price),
            mainImageUrl: mainImgUrl,
            subImageUrls: subImgUrls,
        }
        console.log("[onSave] body", body);
        try {
            const ret = await axios.put(`https://rest.39f.jp/products/${productId}`, body);
            console.log("[onSave] ret", ret);
            toast.success('Product saved successfully');
        } catch (e) {
            console.log("[onSave] error", e);
            toast.error('Failed to save product');
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>Product Editor</PageTitle>
                <Card paddingClassNames='p-0 mb-2'>
                    <div className="flex flex-col space-y-1 p-2">
                        <Input upperLabel='Shop ID' placeholder='01'
                            onChange={(value) => { setFormData({ ...formData, shopId: value }); }}
                            value={formData.shopId}
                        />
                        <Input upperLabel='Category ID' placeholder='01'
                            onChange={(value) => { setFormData({ ...formData, categoryId: value }); }}
                            value={formData.categoryId}
                        />
                        <Input upperLabel='Product Name' placeholder='Product Name'
                            onChange={(value) => { setFormData({ ...formData, productName: value }); }}
                            value={formData.productName}
                        />
                        <Input upperLabel='Product Description' placeholder='Product Description'
                            onChange={(value) => { setFormData({ ...formData, productDescription: value }); }}
                            value={formData.productDescription}
                        />
                        <Input upperLabel='Price' format={InputFormat.NUMBER} placeholder='Price'
                            onChange={(value) => { setFormData({ ...formData, price: value }); }}
                            value={formData.price}
                        />
                        <InputUpperLabel>Images</InputUpperLabel>
                        {/* <FileInput onFilesSelected={onMainImgSelected}></FileInput> */}
                        <Card paddingClassNames='p-0'>
                            <div className="overflow-auto">
                                <div className="flex justify-center space-x-2 p-2 min-w-max">
                                    {imgUrls.map((url, index) => (
                                        <div className="relative border-solid border-2 border-indigo-600 rounded-md" key={index}>
                                            <div className="w-40 h-40">
                                                <span className="absolute top-1 right-1">
                                                    <MinusCircleIcon
                                                        className="size-6 cursor-pointer text-indigo-600"
                                                        onClick={() => {
                                                            setImgUrls(imgUrls.filter((_, i) => i !== index));
                                                        }}
                                                    />
                                                </span>
                                                <img key={index} src={url} className="rounded-md" />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="w-40 h-40">
                                        <ImgDropper onFilesSelected={onFilesSelected}></ImgDropper>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div>
                            <InputUpperLabel>Product Detail</InputUpperLabel>
                            <QuillEditorWithImageUploader placeholder=' ' onQuillReady={(quill) => { quillRef.current = quill }} />
                        </div>
                    </div>
                </Card>
                <div className="flex justify-between space-x-2">
                    <div>
                        <div>
                            <Button onButtonClicked={() => { navigate(-1) }}>Back</Button>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div>
                            <Button onButtonClicked={onDelete} disabled={_.isNil(productId)}>Delete</Button>
                        </div>
                        <div>{_.isNil(productId) ? (
                            <Button onButtonClicked={onCreate}>Create</Button>
                        ) : (
                            <Button onButtonClicked={onSave}>Save</Button>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ProductEditor;
