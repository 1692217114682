
import React from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "pages/Home";

export default () => (
    <Routes>
        <Route path="/home" element={<HomePage />}></Route>
    </Routes>
)

