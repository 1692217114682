import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PublicSideNav from 'containers/SideNav/public';
import PublicSiteRoute from 'containers/SiteRoute/public';

import "./App.scss";


function App() {
  return (
    <div className="main-container">
      <PublicSideNav></PublicSideNav>
      <div className="main-content">
        <PublicSiteRoute></PublicSiteRoute>
      </div>
    </div>
  );
}

export default App;