import { InputFormat } from "components/Form/Input";
import { Column } from "components/Table/Simple";
import { formatDate, formatDateTime, formatDollar } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "productId",
        label: "상품ID",
        visible: true,
    },
    {
        field: "name",
        label: "상품명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "description",
        label: "설명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "price",
        label: "가격",
        visible: true,
        creatable: true,
        editable: true,
        format: InputFormat.NUMBER,
        render: formatDollar,
        align: "right"
    },
    {
        field: "mainImage",
        label: "대표이미지",
        visible: true,
    },
    {
        field: "status",
        label: "상태",
        visible: true,
    },
    {
        field: "createdAt",
        label: "생성일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "updatedAt",
        label: "수정일",
        visible: true,
        render: formatDateTime

    },
    {
        field: "stockQuantity",
        label: "재고수량",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "isActive",
        label: "활성화",
        visible: true,
        creatable: true,
        editable: true,
    }
]