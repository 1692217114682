
import React from "react";
import { Routes, Route } from "react-router-dom";

import ProductEditor from "containers/Store/Product/Editor";
import ProductLists from "containers/Store/Product/Lists";
import ProductOverviews from "containers/Store/Product/Overviews";
import ProductFeatures from "containers/Store/Product/Features";

export default () => (
    <Routes>
        <Route path="/editor" element={<ProductEditor />}>
            <Route path=":productId" element={<ProductLists />}></Route>
        </Route>
        <Route path="/lists" element={<ProductLists />}>
        </Route>
    </Routes>
)

