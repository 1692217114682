import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import numeral from 'numeral';

export const formatDate = (date: string) => {
    if (_.isNil(date)) return "";
    const dateStr = moment(date).format("'YY-MM-DD");
    if (dateStr === "Invalid date") return "";
    return dateStr;
}

export const formatDateTime = (date: string) => {
    if (_.isNil(date)) return "";
    const dateStr = moment(date).format("'YY-MM-DD HH:mm");
    if (dateStr === "Invalid date") return "";
    return dateStr;
}

export const formatDollar = (value: number) => {
    if (_.isNil(value)) return "";
    return numeral(value).format("$ 0,0");
}


export const truncate = (text: string) => {
    if(_.isNil(text)) {
        return "";
    }
    if (text.length > 12) {
        return text.slice(0, 12) + "...";
    } else {
        return text;
    }
}

export const renderHalfWrap = (value: string) => {
    return (
        <div className="text-pretty min-w-[50vw]">
            {value}
        </div>
    )
}

export const renderMediumWrap = (value: string) => {
    return (
        <div className="text-pretty min-w-[33vw]">
            {value}
        </div>
    )
}

export const renderQuarterWrap = (value: string) => {
    return (
        <div className="text-pretty min-w-[25vw]">
            {value}
        </div>
    )
}


export const renderSmallWrap = (value: string) => {
    return (
        <div className="text-pretty min-w-[10vw]">
            {value}
        </div>
    )
}