import React, { useEffect, useRef } from "react"
import { useQuill } from "react-quilljs"
import _ from "lodash";
import Quill from "quill";
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import styles from "../Basic/styles.module.scss"
import QuillResizeImage from "quill-resize-image";
import loaderGif from "./loader.gif";
import axios from "axios";

Quill.register("modules/imageResize", QuillResizeImage);

const QuillEditorWithImageUploader = ({ placeholder = " ", onQuillReady = (quill: Quill) => { }, onChange = (data) => { } }) => {
    const { quill, quillRef } = useQuill({
        theme: 'snow',
        formats: [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'indent',
            'link', 'image'
        ],
        modules: {
            toolbar: {
                container: [
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    [
                        'bold',
                        'italic',
                        'underline',
                        {
                            'color': [
                                'red', 'orange', 'yellow', 'green', 'blue', 'violet', false
                            ]
                        },
                        {
                            'background': [
                                'red', 'orange', 'yellow', 'green', 'blue', 'violet', false
                            ]
                        }
                    ],
                    [{ 'list': 'bullet' }, { 'list': 'ordered' }],
                    ['link', 'image'],
                ],
            },
            imageResize: {

            },
        },
        placeholder,
    })

    useEffect(() => {
        if (_.isNil(quill)) {
            return;
        }
        console.log("[BasicQuillEditor] quill Changed", quill);
        quill.on('text-change', (delta, oldDelta, source) => {
            console.log("[BasicQuillEditor] text-change", delta, oldDelta, source);
            onChange(quill.getContents());
        });
        onQuillReady(quill);
        if (quill) {
            // Add custom handler for Image Upload
            const toolBar: any = quill.getModule('toolbar');
            toolBar.addHandler('image', selectLocalImages); // for multiple images select
            const uploader: any = quill.getModule('uploader');
            uploader.options.handler = imageHandler;
        }
    }, [quill])

    const selectLocalImages = () => {
        const input = document.createElement('input');
        // multiple files
        input.setAttribute('multiple', 'multiple');
        input.setAttribute('accept', 'image/*');
        input.setAttribute('type', 'file');
        input.click();
        input.onchange = () => {
            const files = input.files;
            console.log("!!! FILES", files)
            const range = quill.getSelection();
            imageHandler(range, files);
        };
    }


    const imageHandler = async (range, files) => {
        console.log("!!!!! HANDLE IMAGE", range, files)
        let currentIndex = range.index;
        for await (const file of files) {
            console.log("!!!!! FILE", file);
            quill.insertEmbed(currentIndex, 'image', loaderGif);
            const url = await uploadImage(file);
            quill.deleteText(currentIndex, 1);
            insertToEditor(currentIndex, url);
            currentIndex++;
        }
    }

    const uploadImage = async (file) => {
        try {
            console.log("!!!!! FILE", file)
            // Step 1: Get signed URL from server
            const response = await axios.get(`https://rest.39f.jp/s3/generate-s3-url`, {
                params: {
                    filename: file.name,
                    filetype: file.type
                }
            });

            if (response.status !== 200) {
                console.error('Failed to get signed URL.');
                return;
            }
            console.log("!!!!! RET", response)

            const { url } = response.data;
            // const url = "https://39f-storage.s3.ap-northeast-1.amazonaws.com/uploads/1722752684916_jms?AWSAccessKeyId=AKIAR2EGG6GELIQAJJRT&Content-Type=jms&Expires=1722752744&Signature=00m9lz1hrAqSZ8jfxFT6j2xgGHg%3D&x-amz-acl=public-read"
            // Step 2: Upload image to S3 using the signed URL
            const uploadResponse = await axios.put(url, file, {
                headers: {
                    'Content-Type': file.type
                }
            });

            if (uploadResponse.status === 200) {
                // insertToEditor(url.split('?')[0]);
                return url.split('?')[0];
            } else {
                console.error('Failed to upload image.');
            }
        } catch (error) {
            console.error('An error occurred during the image upload process:', error);
        }
    }

    const insertToEditor = (index, url) => {
        console.log("!!! INSERT", url, quill, quillRef)
        quill.insertEmbed(index, 'image', url);
    }

    return (
        <div className={`${styles.qlWrapper} ${styles.customSize} focus-within:ring-2 focus-within:ring-indigo-600 rounded-md`}>
            <div ref={quillRef} />
        </div>
    )

}

export default QuillEditorWithImageUploader;
