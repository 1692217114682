import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import MainRoute from "containers/SiteRoute/Main";
import ShopRoute from "containers/SiteRoute/Shop";
import CategoryRoute from "containers/SiteRoute/Category";
import ProductRoute from "containers/SiteRoute/Product";
import OrderRoute from "containers/SiteRoute/Order";

export default () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={'/main/home/'} />}></Route>
            <Route path="/main/*" element={<MainRoute />}></Route>
            <Route path="/shop/*" element={<ShopRoute />}></Route>
            <Route path="/category/*" element={<CategoryRoute />}></Route>
            <Route path="/product/*" element={<ProductRoute />}></Route>
            <Route path="/order/*" element={<OrderRoute />}></Route>
        </Routes>
    )
}