import React, { useState } from 'react'
import PagingTable from 'components/Table/Paging'
import axios from 'axios'
import PageTitle from 'components/Title/Page'
import Card from 'components/Card'
import { columns } from './config'
import { addColumns, modifyColumns } from 'components/Table/Simple/helper'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Form/Button'
import _ from 'lodash'
import { toast } from 'react-toastify'

const ProductList = () => {
    const navigate = useNavigate();
    const [refreshKey, setRefreshKey] = useState(_.uniqueId());

    const fetchData = async (page, limit) => {
        try {
            const params = {
                page: 1,
                limit: 100
            }
            const response = await axios.get(`https://rest.39f.jp/products`, { params })
            return {
                data: response.data.data,
                total: response.data.length,
                page: page,
                limit: limit
            }
        } catch (error) {
            console.error("[fetchData] error", error)
            return {
                data: [],
                total: 0,
                page: page,
                limit: limit
            }
        }
    }

    const newColumns = modifyColumns(columns, [
        {
            field: "mainImageUrl",
            visible: false
        },
        {
            field: "subImageUrls",
            visible: false
        },
        {
            field: "detail",
            visible: false
        }
    ])

    const addedColumns = addColumns(newColumns, [
        {
            field: "detailEdit",
            label: "Detail Edit",
            visible: true,
            align: "center",
            render: (value, field, row) => {
                return (
                    <PencilSquareIcon className="size-4 cursor-pointer" onClick={() => {
                        console.log("[edit] row", row)
                        navigate(`/product/editor/${row.id}`)
                    }} />
                )
            }
        }
    ]);

    const onCreate = async (data) => {
        console.log("[onCreate] callback", data);
        const newProduct = {
            shopId: Number(data.shopId),
            categoryId: Number(data.categoryId),
            name: data.name,
            description: data.description,
            price: Number(data.price),
        }
        try {
            const ret = await axios.post(`https://rest.39f.jp/products`, newProduct);
            console.log("[onCreate] ret", ret);
            toast.success('Product saved successfully');
            setRefreshKey(_.uniqueId());
            return true;
        } catch (e) {
            console.error("[onCreate] error", e);
            toast.error('Failed to save product');
            return false;
        }
    }

    const onEdit = async (data) => {
        console.log("[onEdit] callback", data);
        const updatedFields = {
            ...(data.shopId && { shopId: Number(data.shopId) }),
            ...(data.categoryId && { categoryId: Number(data.categoryId) }),
            ...(data.price && { price: Number(data.price) }),
        }
        console.log("[onEdit] updatedFields", updatedFields);
        try {
            const ret = await axios.put(`https://rest.39f.jp/products/${data.productId}`, _.omit(updatedFields, ['id']));
            console.log("[onEdit] ret", ret);
            toast.success('Product updated successfully');
            setRefreshKey(_.uniqueId());
            return true;
        } catch (e) {
            console.error("[onEdit] error", e);
            toast.error('Failed to update product');
            return false;
        }
    }

    const onDelete = async (data) => {
        console.log("[onDelete] callback", data);
        const ok = window.confirm('Are you sure to delete this product?');
        if (!ok) {
            return false;
        }
        try {
            const ret = await axios.delete(`https://rest.39f.jp/products/${data.productId}`);
            console.log("[onDelete] ret", ret);
            toast.success('Product deleted successfully');
            setRefreshKey(_.uniqueId());
            return true;
        } catch (e) {
            console.error("[onDelete] error", e);
            toast.error('Failed to delete product');
            return false;
        }
    }

    return (
        <div className="">
            <Card>
                <div className="p-3">
                    <PageTitle>Product List</PageTitle>
                    <PagingTable
                        refreshKey={refreshKey}
                        fetchData={fetchData}
                        columns={addedColumns}
                        isCrudable={true}
                        onCreate={onCreate}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </div>
            </Card>
        </div>
    )
}

export default ProductList